import React, { useEffect, useState, useRef } from 'react';
import './components.css';
import {
    Card,
    Modal,
    Row,
    Col
} from 'react-bootstrap';
import { modService } from './ModService';
import { ResourceExplorerFile } from './ResourceExplorerFile';
import { FileUploader } from 'react-drag-drop-files';
import { GenericFile } from './GenericFile';
import { GenericInput } from './GenericInput';
import { GenericBtn } from './GenericBtn';
import { GenericEnum } from './GenericEnum';
import { ResourceExplorerDirectory } from './ResourceExplorerDirectory';
import { authorization, Permission } from '../Authorization';

interface ResourceExplorerProps {
    pack: string;
    path: string;
    onSelect: (value: string, pack: string) => void;
    onHidden: () => void;
}

export const ResourceExplorer: React.FC<ResourceExplorerProps> = ({ pack, path, onSelect, onHidden }) => {
    const [files, setFiles] = useState<string[]>([]);
    const [direcotries, setDirecotries] = useState<string[]>([]);
    const [_selectedPath, _setSelectedPath] = useState<string>(path);
    const [newDirectoryName, setNewDirectoryName] = useState<string>("");
    const [_selectedPack, _setSelectedPack] = useState<string>(pack);
    const [packages, setPackages] = useState<string[]>([]);

    const selectedPath = useRef<string>(_selectedPath);
    const selectedPack = useRef<string>(_selectedPack);

    const updateFiles = (pack: string, p: string) => {
        console.log("Selected path: " + p);

        modService.getPackResources(pack, p).then(data => {
            let f: string[] = [], d: string[] = [];

            data.forEach(file => {
                if (file.includes(".")) {
                    f.push(file);
                } else {
                    d.push(file);
                }
            });

            setFiles(f);
            setDirecotries(d);
        });
    }

    const setSelectedPath = (path: string) => {
        _setSelectedPath(path);
        selectedPath.current = path;
        updateFiles(selectedPack.current, path);
    };

    const setSelectedPack = (pack: string) => {
        _setSelectedPack(pack);
        selectedPack.current = pack;
        updateFiles(selectedPack.current, selectedPath.current);
        setSelectedPath("");
    }

    const onClick = (file: string) => {
        console.log(file);

        // If the file is a directory (no extention), change the selected path
        if (!file.includes(".")) {
            setSelectedPath(file);
            return;
        }

        console.log("Selected file: " + file);

        // If the file is a file, select it
        onSelect(file, selectedPack.current);
    };

    useEffect(() => {
        updateFiles(selectedPack.current, path);

        modService.getModPacks().then(data => {
            data.push("lego-universe");

            setPackages(data);
        });
    }, []);

    const handleFileUpload = (file: File) => {
        let p = selectedPath.current;

        // If p does not end with a /, add one
        if (!p.endsWith("/")) {
            p += "/";
        }

        p += file.name;

        // Remove first / if it exists
        if (p.startsWith("/")) {
            p = p.substring(1);
        }

        modService.uploadFile(file, pack, p).then(finalPath => {
            if (finalPath === "") {
                alert("Error uploading file");
                return;
            }

            updateFiles(selectedPack.current, selectedPath.current);
        });
    }

    const onCreateDirectory = () => {
        let p = selectedPath.current;

        // If p does not end with a /, add one
        if (!p.endsWith("/")) {
            p += "/";
        }

        p += newDirectoryName;

        modService.createDirectory(pack, p).then(success => {
            if (!success) {
                alert("Error creating directory");
                return;
            }

            updateFiles(selectedPack.current, selectedPath.current);
        });
    }

    const onDeleteFile = (file: string) => {
        modService.deleteFile(pack, file).then(success => {
            if (!success) {
                alert("Error deleting file");
                return;
            }

            updateFiles(selectedPack.current, selectedPath.current);
        });
    }

    const parts = selectedPath.current.split("/");

    const canWriteResouces = authorization.hasPermission(Permission.WriteResources);

    return (
        <Modal size='xl' show={true} onHide={() => {
            onHidden();
        }}>
            <Card className='vs-bg p-2'>
                <Row className='left-text p-0 m-0 border-bottom'>
                    <Col md={6}>
                        <p className='wheat-text monospace w-100 '>
                            <span className='pointer' onClick={() => {
                                setSelectedPath("");
                            }
                            }>
                                <u>{selectedPack.current}</u>
                            </span>
                            /
                            {parts.map((part, index) => {
                                return (
                                    <span key={index} className='pointer' onClick={() => {
                                        const parts = selectedPath.current.split("/");
                                        parts.splice(index + 1, parts.length - index - 1);
                                        setSelectedPath(parts.join("/"));
                                    }
                                    }>
                                        <u>{part}</u>
                                        {index < parts.length - 1 &&
                                            <span>/</span>
                                        }
                                    </span>
                                );
                            })}
                        </p>
                    </Col>
                    <Col md={6}>
                        <GenericEnum title="" value={selectedPack.current} setValue={setSelectedPack} options={packages} />
                    </Col>
                </Row>
                <Row className='p-0 m-0'>
                    <Col className='border-right h-100' md={3}>
                        <Row>
                            <ResourceExplorerDirectory path={".."} onSelect={() => {
                                const parts = selectedPath.current.split("/");
                                parts.pop();
                                setSelectedPath(parts.join("/"));
                            }} />
                            {direcotries.map((directory, index) => {
                                return (
                                    <ResourceExplorerDirectory key={index} path={directory} onSelect={() => {
                                        setSelectedPath(directory);
                                    }} />
                                );
                            })}
                        </Row>
                    </Col>
                    <Col md={9}>
                        <Row>
                            {files.map((file, index) => {
                                return (
                                    <ResourceExplorerFile key={index} pack={selectedPack.current} path={file} onSelect={() => {
                                        onClick(file);
                                    }} onDeleted={() => {
                                        onDeleteFile(file);
                                    }} />
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
                {selectedPack.current !== 'lego-universe' && selectedPack.current !== 'wonderland' && canWriteResouces
                    &&
                    <>
                        <Row className='p-0 m-0'>
                            <Col md={4}>
                                <GenericFile title="" types={["PNG", "DDS", "NIF", "HKX", "LUA"]} onUpload={handleFileUpload} />
                            </Col>
                            <Col md={8}>
                                <Row className='pt-2'>
                                    <Col md={8}>
                                        <GenericInput title="" type="text" value={newDirectoryName} setValue={setNewDirectoryName} />
                                    </Col>
                                    <Col md={4}>
                                        <GenericBtn title="Create Directory" onClick={onCreateDirectory} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                }
            </Card>
        </Modal>
    );
};