import Cookies from 'js-cookie';

interface Project {
    name: string;
    port: number;
    database: boolean;
    gameServer: boolean;
    isPublic: boolean;
}

interface AuthData {
    token?: string;
    error?: string;
}

class ModService {
    private endpoint: string;
    private mods: { [key: string]: string[] } = {};
    private templates: { [key: string]: any } = {};
    private modTypes: string[] = [];
    private lastModsUpdate: number = 0;

    public constructor() {
        // If we are on nejlika.org (or www.nejlika.org), use app.nejlika.org/api
        if (window.location.hostname === "nejlika.org" || window.location.hostname === "www.nejlika.org") {
            this.endpoint = "https://nejlika.org/api";
            return;
        }
        if (window.location.hostname === "localhost") {
            this.endpoint = "http://localhost:8080/api";
            return;
        }
        this.endpoint = "https://nejlika.org/api";
        return;

        // Get the url, change the port to 8080
        const url = new URL(window.location.href);
        url.port = "8080";

        // Get without any path or query
        url.pathname = "";
        url.search = "";
        url.hash = "";
        url.pathname = "";

        // Set the endpoint

        console.log(url.toString());

        this.endpoint = url.toString() + "api";
    }

    public getEndpoint(): string {
        return this.endpoint;
    }

    public getIconUrl(name: string): string {
        return `${this.endpoint}/resources/icon?icon=${(encodeURIComponent(name))}`;
    }

    public getImageUrl(pack: string, path: string): string {
        return `${this.endpoint}/resources/image?pack=${encodeURIComponent(pack)}&path=${encodeURIComponent(path)}`;
    }

    public getModPackage(modName: string): string {
        // Get the mod package from the mod name
        return modName.split(":")[0];
    }

    private addHeaders(obj: any): any {
        return obj;
    }

    public async auth(code: string): Promise<AuthData> {
        const response = await fetch(`${this.endpoint}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "code": code
            })
        });

        const data = await response.json();

        if (!response.ok) {
            console.log(response);
            return { error: data["error"] };
        }

        return { token: data["token"] };
    }

    public async isAuthorized(): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/auth`, this.addHeaders({
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async refreshToken(): Promise<string> {
        const response = await fetch(`${this.endpoint}/api/renew`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return "";
        }

        const data = await response.json();

        return data["token"];
    }

    public async getMods(pack: string): Promise<string[]> {
        const response = await fetch(`${this.endpoint}/pack`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": pack
            })
        }));

        if (!response.ok) {
            console.log(response);
            return [];
        }

        const data = await response.json();

        // Update the cache
        this.mods[pack] = data["mods"];

        // Update the last update time
        this.lastModsUpdate = Date.now();

        return data["mods"];
    }

    public async getProjects(): Promise<string[]> {
        const response = await fetch(`${this.endpoint}/projects`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return [];
        }

        const data = await response.json();

        return data["projects"];
    }

    public async getProject(name: string): Promise<Project | null> {
        const response = await fetch(`${this.endpoint}/project?name=${name}`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return null;
        }

        const data = await response.json();

        return {
            name: data["name"],
            port: data["port"],
            database: data["database-status"],
            gameServer: data["server-status"],
            isPublic: data["public"]
        }
    }

    public async createProject(name: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/create`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name
            })
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async createPersonalProject(): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/create/personal`, this.addHeaders({
            method: 'POST'
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async loadProject(name: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/load?name=${name}`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async startProject(name: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/start?name=${name}`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async stopProject(name: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/stop?name=${name}`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async resetProject(name: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/reset?name=${name}`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async reapplyProject(name: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/reapply?name=${name}`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async getIsProjectPublic(name: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/public?name=${name}`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        const data = await response.json();

        return data["public"];
    }

    public async setProjectPublic(name: string, isPublic: boolean): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/public?name=${name}`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "isPublic": isPublic
            })
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async inviteUserToProject(
        name: string,
        discordName: string,
        readResources: boolean,
        writeResources: boolean,
        readMods: boolean,
        writeMods: boolean,
        deploy: boolean,
        login: boolean,
        adminLevel: number,
        inviteUsers: boolean
    ): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/project/invite?name=${name}`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "discord_name": discordName,
                "read_resources": readResources,
                "write_resources": writeResources,
                "read_mods": readMods,
                "write_mods": writeMods,
                "deploy": deploy,
                "login": login,
                "admin_level": adminLevel,
                "invite_users": inviteUsers
            })
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async deleteAccount(): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/account/delete`, this.addHeaders({
            method: 'POST'
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async downloadAccountData(): Promise<any|null> {
        const response = await fetch(`${this.endpoint}/account/download`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return null;
        }
        
        return response.json();
    }

    public async getModTemplate(template: string): Promise<any> {
        if (this.templates[template]) {
            return this.templates[template];
        }

        const response = await fetch(`${this.endpoint}/type?type=${template}`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return {};
        }

        const data = await response.json();

        this.templates[template] = data;

        return data;
    }

    public async getPackResources(pack: string, path: string): Promise<string[]> {
        const response = await fetch(`${this.endpoint}/pack/resources`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": pack,
                "path": path
            })
        }));

        if (!response.ok) {
            console.log(response);
            return [];
        }

        const data = await response.json();

        return data["resources"];
    }

    public async getPackInformation(pack: string): Promise<any> {
        // `${this.endpoint}/mods/pack`
        const response = await fetch(`${this.endpoint}/pack`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": pack
            })
        }));

        if (!response.ok) {
            console.log(response);
            return [];
        }

        const data = await response.json();

        // Update the cache
        this.mods[pack] = data["mods"];

        // Update the last update time
        this.lastModsUpdate = Date.now();

        return data["manifest"];
    }

    public async getModPacks(): Promise<string[]> {
        const response = await fetch(`${this.endpoint}/packs`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return [];
        }

        const data = await response.json();

        return data["packs"];
    }

    public async saveModPacks(): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/packs/save`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async createPack(name: string, description: string, author: string, version: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/pack/new`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name,
                "description": description,
                "author": author,
                "version": version
            })
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async updatePack(oldName: string, newName: string, description: string, author: string, version: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/pack/patch`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "oldName": oldName,
                "newName": newName,
                "description": description,
                "author": author,
                "version": version
            })
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }


    public async getMod(mod: string): Promise<{ json: any, uid: number }> {
        const response = await fetch(`${this.endpoint}/mod`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": mod
            })
        }));

        if (!response.ok) {
            console.log(response);
            return { json: {}, uid: 0 };
        }

        const data = await response.json();
        return { json: data["json"], uid: data["uid"] };
    }

    public async getModByUid(uid: number): Promise<{ json: any, name: string }> {
        const response = await fetch(`${this.endpoint}/mod`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "uid": uid
            })
        }));

        if (!response.ok) {
            console.log(response);
            return { json: {}, name: "" };
        }

        const data = await response.json();
        return { json: data["json"], name: data["json"]["name"] };
    }

    public async updateMod(json: any, uid: number): Promise<{ name: string, uid: number, error: string }> {
        const response = await fetch(`${this.endpoint}/mod/patch`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "json": json,
                "uid": uid
            })
        }));

        if (!response.ok) {
            const errorData = await response.json();
            return { name: "", uid: 0, error: errorData["error"] };
        }

        let data = await response.json();

        data["error"] = "";

        return data;
    }

    public async validateJson(json: any): Promise<{ error: string }> {
        const response = await fetch(`${this.endpoint}/type/validate`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "json": json
            })
        }));

        if (!response.ok) {
            try {
                const data = await response.json();

                return data;
            } catch (e) {
                return { error: "Unknown error" };
            }
        }
        
        return { error: "" };
    }

    public async getModTemplateNames(): Promise<string[]> {
        if (this.modTypes.length > 0) {
            return this.modTypes;
        }

        const response = await fetch(`${this.endpoint}/types`, this.addHeaders({
            method: 'GET'
        }));

        if (!response.ok) {
            console.log(response);
            return [];
        }

        const data = await response.json();

        this.modTypes = data;

        return data;
    }

    public async createMod(json: any): Promise<{ name: string, uid: number }> {
        const response = await fetch(`${this.endpoint}/mod/new`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "json": json
            })
        }));

        if (!response.ok) {
            console.log(response);
            return { name: "", uid: 0 };
        }

        const data = await response.json();

        return data;
    }

    public async deleteMod(uid: number): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/mod/delete`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "uid": uid
            })
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        const data = await response.json();

        return data["success"];
    }

    public async getUid(name: string): Promise<number> {
        const response = await fetch(`${this.endpoint}/mod/uid`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "name": name
            })
        }));

        if (!response.ok) {
            console.log(response);
            return 0;
        }

        const data = await response.json();

        return data["uid"];
    }

    public getSaveUrl(): string {
        return `${this.endpoint}/save`;
    }

    public async save(): Promise<any> {
        const response = await fetch(this.getSaveUrl(), this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        }));

        if (!response.ok) {
            return response.json();
        }

        const data = await response.json();

        return { res: data["resources"], db: data["database"] };
    }

    public async getComponentFromDatabase(type: string, id: number): Promise<any> {
        const response = await fetch(`${this.endpoint}/mod/component`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "type": type,
                "id": id
            })
        }));

        if (!response.ok) {
            console.log(response);
            return {};
        }

        const data = await response.json();

        return data;
    }

    public async queryMod(type: string, id?: number): Promise<{ [key: string]: any }> {
        if (id === undefined) {
            id = 0;
        }

        const response = await fetch(`${this.endpoint}/mod/query`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "type": type,
                "id": id
            })
        }));

        if (!response.ok) {
            console.log(response);
            return {};
        }

        const data = await response.json();

        return data;

    }

    public async sql(query: string): Promise<{ [key: string]: string | number | boolean | null }[]> {
        const response = await fetch(`${this.endpoint}/sql`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "sql": query
            })
        }));

        if (!response.ok) {
            console.log(response);
            return [];
        }

        const data = await response.json();

        return data["result"];
    }

    public async loadSkill(id: number, prefix: string): Promise<any> {
        const response = await fetch(`${this.endpoint}/mod/load/skill`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "id": id,
                "prefix": prefix
            })
        }));

        if (!response.ok) {
            console.log(response);
            return {};
        }

        const data = await response.json();

        let mod = data["json"];

        const findBehavior = (name: string) => {
            for (const behavior of mod.values.behaviors) {
                if (behavior.name === name) {
                    return behavior;
                }
            }

            return null;
        };

        let depths: { [key: number]: number } = {};
        let positions: {[key: string]: {depth: number, siblingNr: number}} = {};

        const computePositions = (node: any, depth: number) => {
            // If this behavior has been visited, return
            if (positions[node.name]) {
                return;
            }

            if (depths[depth] === undefined) {
                depths[depth] = 0;
            }

            if (positions[node.name] === undefined) {
                positions[node.name] = {depth: depth, siblingNr: depths[depth]};
            }

            depths[depth]++;

            if (!node.values.parameters) {
                return;
            }

            console.log(node.values.parameters)

            for (const key of Object.keys(node.values.parameters)) {
                const child = node.values.parameters[key];
                if (typeof child === "string") {
                    const behavior = findBehavior(child);

                    if (behavior) {
                        computePositions(behavior, depth + 1);
                    }
                }
            }
        };

        computePositions(findBehavior(mod.values.behavior), 0);

        // Each column is 200 in width (x), each row is 100 in height (y)
        for (const behavior of mod.values.behaviors) {
            const position = positions[behavior.name];

            // Normalize the siblingNr, based on the number of siblings
            let numberOfSiblings = depths[position.depth];
            let siblingNr = position.siblingNr;
            let normalizedSiblingNr = siblingNr - (numberOfSiblings - 1) / 2;


            if (position) {
                behavior["extra-data"] = {
                    position: {
                        x: position.depth * 500 + 800,
                        y: normalizedSiblingNr * 100
                    }
                }
            }
        }

        return mod;
    }

    public async uploadFile(file: File, pack: string, path: string): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        let url = `${this.endpoint}/resources/upload?pack=${encodeURI(pack)}&path=${encodeURI(path)}`;

        const response = await fetch(url, this.addHeaders({
            method: 'POST',
            body: formData
        }));

        if (!response.ok) {
            console.log(response);
            return "";
        }

        const data = await response.json();

        return data["path"];
    }

    public async deleteFile(pack: string, path: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/resources/delete`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "pack": pack,
                "path": path
            })
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }

    public async createDirectory(pack: string, path: string): Promise<boolean> {
        const response = await fetch(`${this.endpoint}/resources/create_directory`, this.addHeaders({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "pack": pack,
                "path": path
            })
        }));

        if (!response.ok) {
            console.log(response);
            return false;
        }

        return true;
    }
}

// Singleton
export const modService = new ModService();
