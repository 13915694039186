import { useState, useEffect, useRef } from 'react';
import logo from './logo.svg';
import './App.css';
import { Navigation } from './components/Navigation';
import { Editor } from './components/Editor';
import { Explorer } from './components/Explorer';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Home from './Home';
import ExplorerHome from './ExplorerHome';
import LoadHome from './LoadHome';
import { Auth } from './components/Auth';
import Cookies from 'js-cookie';
import { modService } from './components/ModService';
import { GenericBtn } from './components/GenericBtn';
import NewModHome from './NewModHome';
import { UnityPlayer } from './components/Unity/UnityPlayer';
import { UnityNavigator } from './components/Unity/UnityNavigator';
import { UnityPanel } from './components/UnityPanel';
import { ThreeDots } from 'react-loading-icons';
import { WelcomePage } from './components/WelcomePage/WelcomePage';
import CookieBanner from './components/CookieBanner/CookieBanner';
import ImportantNoticeModal from './components/ImportantNoticeModal/ImportantNoticeModal';
import { Button } from 'react-bootstrap';
import PrivacyPolicy from './components/Privacy/PrivacyPolicy';
import { LauncherPage } from './components/Launcher/LauncherPage';

function App() {
    const [guiEnabled, setGuiEnabled] = useState<boolean>(true);
    const [showUnity, setShowUnity] = useState<boolean>(false);
    const [showEditor, setShowEditor] = useState<boolean>(true);
    const [showExplorer, setShowExplorer] = useState<boolean>(true);
    const [showLogger, setShowLogger] = useState<boolean>(true);
    const [showGuide, setShowGuide] = useState<boolean>(true);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Standard />}>
                        <Route index element={<WelcomePage />} />
                        <Route path='launcher' element={<LauncherPage />} />
                    </Route>
                    <Route path="/editor" element={<EdiorPage
                        guiEnabled={guiEnabled}
                        setGuiEnabled={setGuiEnabled}
                        showUnity={showUnity}
                        setShowUnity={setShowUnity}
                        showEditor={showEditor}
                        setShowEditor={setShowEditor}
                        showExplorer={showExplorer}
                        setShowExplorer={setShowExplorer}
                        showLogger={showLogger}
                        setShowLogger={setShowLogger}
                        showGuide={showGuide}
                        setShowGuide={setShowGuide}
                    />}>
                        <Route index element={<Home
                            guiEnabled={guiEnabled}
                            setGuiEnabled={setGuiEnabled}
                            showUnity={showUnity}
                            setShowUnity={setShowUnity}
                            showEditor={showEditor}
                            setShowEditor={setShowEditor}
                            showExplorer={showExplorer}
                            setShowExplorer={setShowExplorer}
                            showLogger={showLogger}
                            setShowLogger={setShowLogger}
                            showGuide={showGuide}
                            setShowGuide={setShowGuide}
                        />} />
                        <Route path='load' element={<LoadHome />} />
                        <Route path='new' element={<NewModHome />} />
                        <Route path='unity/:mode/:item' element={<UnityNavigator />} />
                        <Route path='unity' element={<UnityPanel />} />
                    </Route>
                    <Route path='auth' element={<Auth />} />
                    <Route path='/privacy' element={<PrivacyPolicy />} />
                    <Route path="/embed">
                        <Route path='unity/:mode/:item' element={<UnityNavigator />} />
                    </Route>
                    <Route path='*' element={<p>404 :/</p>} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

const Standard: React.FC = () => {
    return (
        <>
            <ImportantNoticeModal />
            <div className="App">
                <Outlet />
            </div>
            <CookieBanner />
        </>
    );
};

interface EdiorProps {
    guiEnabled: boolean;
    setGuiEnabled: (guiEnabled: boolean) => void;
    showUnity: boolean;
    setShowUnity: (showUnity: boolean) => void;
    showEditor: boolean;
    setShowEditor: (showEditor: boolean) => void;
    showExplorer: boolean;
    setShowExplorer: (showExplorer: boolean) => void;
    showLogger: boolean;
    setShowLogger: (showLogger: boolean) => void;
    showGuide: boolean;
    setShowGuide: (showGuide: boolean) => void;
}

const EdiorPage: React.FC<EdiorProps> = ({
    guiEnabled, setGuiEnabled,
    showUnity, setShowUnity,
    showEditor, setShowEditor,
    showExplorer, setShowExplorer,
    showLogger, setShowLogger,
    showGuide, setShowGuide
}) => {
    const [hasAuth, setHasAuth] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);
    const [myInterval, setMyInterval] = useState<any>(null);
    const [_lastAuth, _setLastAuth] = useState<number>(Cookies.get('lastAuth') ? parseInt(Cookies.get('lastAuth')!) : 0);

    const lastAuth = useRef<number>(_lastAuth);

    const setLastAuth = (value: number) => {
        _setLastAuth(value);
        lastAuth.current = value;
    };

    const attemptFirstAuth = () => {
        modService.isAuthorized().then(success => {
            setHasAuth(true);

            if (!success) {
                setFailed(true);

                return;
            }

            setFailed(false);
        });
    }

    const attemptToAuth = () => {
        if (lastAuth.current + 10000 > Date.now()) {
            return;
        }

        setLastAuth(Date.now());

        Cookies.set('lastAuth', Date.now().toString(), { expires: 3600, path: '/', domain: 'nejlika.org', sameSite: 'none', secure: true });

        modService.isAuthorized().then(success => {
            setHasAuth(true);

            if (!success) {
                setFailed(true);

                return;
            }

            setFailed(false);
        });
    };

    useEffect(() => {
        attemptFirstAuth();

        clearInterval(myInterval);

        const interval = setInterval(() => {
            attemptToAuth();
        }, 5000);

        setMyInterval(interval);
    }, []);

    const login = () => {
        window.location.href = 'https://discord.com/oauth2/authorize?client_id=1214686736358051930&response_type=code&redirect_uri=https%3A%2F%2Fnejlika.org%2Fauth&scope=identify';
    }

    return (
        <>
            <ImportantNoticeModal />
            {hasAuth && <>
                {!failed &&
                    <div className="App vs-bg">
                        <header className='main-header vs-bg fixed-top'>
                            <Navigation
                                guiEnabled={guiEnabled} setGuiEnabled={setGuiEnabled}
                                showUnity={showUnity} setShowUnity={setShowUnity}
                                showEditor={showEditor} setShowEditor={setShowEditor}
                                showExplorer={showExplorer} setShowExplorer={setShowExplorer}
                                showLogger={showLogger} setShowLogger={setShowLogger}
                                showGuide={showGuide} setShowGuide={setShowGuide}
                            />
                        </header>
                        <div className='main-header vs-bg'>
                        </div>
                        <Outlet />
                    </div>
                }
                {failed &&
                    <div className="App">
                        <div className="position-absolute top-50 start-50 translate-middle">
                            <div className="text-center">
                                <Button onClick={login} variant="primary">Login</Button>
                            </div>
                        </div>
                    </div>
                }
            </>}
            {!hasAuth &&
                <div className="App">
                    <div className="position-absolute top-50 start-50 translate-middle">

                    </div>
                </div>
            }
            <CookieBanner />
        </>
    );
}

export default App;
