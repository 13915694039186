import { useEffect, useState, useRef } from 'react';
import './App.css';
import { Editor } from './components/Editor';
import { Explorer } from './components/Explorer';
import { OpenFile } from './components/OpenFile';
import GridLayout, { Layout } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import Cookies from 'js-cookie';
import { Logger } from './components/Logger';
import { UnityPanel } from './components/UnityPanel';
import { Guide } from './components/Guide/Guide';
import { GuideHandler, GuideHandlers } from './components/Guide/GuideHandler';

interface HomeProps {
    guiEnabled: boolean;
    setGuiEnabled: (guiEnabled: boolean) => void;
    showUnity: boolean;
    setShowUnity: (showUnity: boolean) => void;
    showEditor: boolean;
    setShowEditor: (showEditor: boolean) => void;
    showExplorer: boolean;
    setShowExplorer: (showExplorer: boolean) => void;
    showLogger: boolean;
    setShowLogger: (showLogger: boolean) => void;
    showGuide: boolean;
    setShowGuide: (showGuide: boolean) => void;
}

const Home: React.FC<HomeProps> = ({
    guiEnabled, setGuiEnabled,
    showEditor, setShowEditor,
    showExplorer, setShowExplorer,
    showLogger, setShowLogger,
    showUnity, setShowUnity,
    showGuide, setShowGuide
}) => {
    const widthCalc = () => Math.max(window.innerWidth - 40, 720);

    const [internalOpenEditors, internalSetOpenEditors] = useState<OpenFile[]>([]);
    const [layoutWidth, setLayoutWidth] = useState(widthCalc());
    const [layout, setLayout] = useState<Layout[] | null>(null);  // Add layout state

    const refOpenEditors = useRef(internalOpenEditors);

    const setOpenEditors = (newOpenEditors: OpenFile[]) => {
        internalSetOpenEditors(newOpenEditors);
        refOpenEditors.current = newOpenEditors;
        console.log('openEditors', newOpenEditors);
    };

    useEffect(() => {
        refOpenEditors.current = internalOpenEditors;
    }, [internalOpenEditors]);

    useEffect(() => {
        const handleResize = () => setLayoutWidth(widthCalc());
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Default layout with x, y, w, and h
    /*
        |            |explorer|
        | editor     |________|
        |            |guide   |
        |____________|________|
        |          logger     |
    */
    const defaultLayout: Layout[] = [
        { i: 'editor', x: 0, y: 0, w: 8, h: 24 },
        { i: 'explorer', x: 8, y: 0, w: 4, h: 16 },
        { i: 'unity', x: 0, y: 24, w: 12, h: 30 },
        { i: 'guide', x: 8, y: 16, w: 4, h: 8 },
        { i: 'logger', x: 0, y: 24, w: 12, h: 4 }
    ];

    // On mount, load the saved w and h values from cookies
    useEffect(() => {
        const savedLayout = Cookies.get('gridLayout');
        if (savedLayout) {
            const savedDimensions = JSON.parse(savedLayout);
            // Merge saved w and h with default layout
            const newLayout = defaultLayout.map((item) => {
                const savedItem = savedDimensions.find((saved: Layout) => saved.i === item.i);
                return savedItem ? { ...item, w: savedItem.w, h: savedItem.h, x: savedItem.x, y: savedItem.y } : item;
            });
            setLayout(newLayout);  // Set the new layout with saved dimensions
        } else {
            setLayout(defaultLayout);  // Use default layout if no saved layout exists
        }
    }, []);

    // Save only w and h when layout changes
    const onLayoutChange = (newLayout: Layout[]) => {
        let dimensionsToSave = newLayout.map(({ i, w, h, x, y }) => ({ i, w, h, x, y }));  // Extract only i, w, h
        // Merge into saved layout
        let savedLayout = layout?.map((item) => {
            const savedItem = dimensionsToSave.find((saved: Layout) => saved.i === item.i);
            return savedItem ? { ...item, w: savedItem.w, h: savedItem.h, x: savedItem.x, y: savedItem.y } : item;
        });

        Cookies.set('gridLayout', JSON.stringify(savedLayout), { expires: 360, sameSite: 'strict' });  // Save to cookies

        setLayout(newLayout);  // Update layout state
    };

    return (
        <div className='vs-bg row p-0 h-90' id='main'>
            {layout === null && <div>Loading...</div>}
            {layout !== null &&
                <GridLayout
                    className="layout"
                    layout={layout}
                    cols={12}
                    rowHeight={30}
                    width={layoutWidth}
                    maxRows={54}
                    draggableHandle=".drag-handle"  // Only the header will be draggable
                    onLayoutChange={onLayoutChange}  // Save layout on change
                    isBounded={false}
                    resizeHandles={['nw', 'se', 'sw', 's']}
                >
                    {showEditor &&
                        <div key="editor">
                            <div className='panel-container' id="editor-panel">
                                <div className="panel-header drag-handle">
                                    <p className='panel-header-title'>Editor</p>
                                </div>
                                <div className="panel-content vs-bg">
                                    <Editor
                                        openEditors={refOpenEditors.current}
                                        setOpenEditors={setOpenEditors}
                                        guiEnabled={guiEnabled}
                                        setGuiEnabled={setGuiEnabled}
                                    />
                                </div>
                            </div>
                            <GuideHandler element="editor-panel" />
                        </div>
                    }
                    {showExplorer &&
                        <div key="explorer">
                            <div className='panel-container' id="explorer-panel">
                                <div className="panel-header drag-handle">
                                    <p className='panel-header-title'>Explorer</p>
                                </div>
                                <div className="panel-content vs-bg">
                                    <Explorer openEditors={refOpenEditors.current} setOpenEditors={setOpenEditors} />
                                </div>
                            </div>
                            <GuideHandler element="explorer-panel" />
                        </div>
                    }
                    {showLogger &&
                        <div key="logger">
                            <div className='panel-container' id="logger-panel">
                                <div className="panel-header drag-handle">
                                    <p className='panel-header-title'>Logger</p>
                                </div>
                                <div className="panel-content vs-bg">
                                    <Logger />
                                </div>
                            </div>
                            <GuideHandler element="logger-panel" />
                        </div>
                    }
                    {showUnity &&
                        <div key="unity">
                            <div className='panel-container' id="unity-panel">
                                <div className="panel-header drag-handle">
                                    <p className='panel-header-title'>Unity</p>
                                </div>
                                <div className="panel-content vs-bg">
                                    <UnityPanel />
                                </div>
                            </div>
                            <GuideHandler element="unity-panel" />
                        </div>
                    }
                    {showGuide &&
                        <div key="guide">
                            <div className='panel-container' id="guide-panel">
                                <div className="panel-header drag-handle">
                                    <p className='panel-header-title'>Guide</p>
                                </div>
                                <div className="panel-content vs-bg">
                                    <Guide />
                                </div>
                            </div>
                            <GuideHandler element="guide-panel" />
                        </div>
                    }
                </GridLayout>
            }
        </div>
    );
}

export default Home;
